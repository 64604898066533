<template>
  <div class="business_details" v-loading="loading">
    <div class="header">
      <div class="resume">
        <router-link to="/" target="_blank">
          <img src="@/static/job/detail_banner.png" alt="完善简历"/>
        </router-link>
      </div>
      <div class="top flex relative">
        <div class="logo">
          <img :src="detail.logo" :alt="detail.companyName" :class="detail.logo?'':'err_logo'"/>
        </div>
        <div class="info">
          <h1>
            {{ detail.companyName }}
            <small v-if="detail.auditStatus">机构认证</small>
          </h1>
          <a :href="detail.website" target="_blank" v-if="detail.website">{{ detail.website }}</a>
        </div>
        <div class="position">
          <h4>{{ form.total }}</h4>
          <p>在招职位</p>
        </div>
      </div>
      <ul class="bottom_tab flex">
        <li :class="{ active: active == 0 }" @click="active = 0">
          <span>企业主页</span>
        </li>
        <li :class="{ active: active == 1 }" @click="active = 1">
          <span>在招职位（{{ form.total }}）</span>
        </li>
      </ul>
    </div>
    <div class="content flex">
      <div class="left">
        <div class="info" v-if="active == 0">
          <h1>企业信息</h1>
          <div class="text" v-html="detail.content"></div>
          <h1>企业地址</h1>
          <div class="address relative">
            <i class="el-icon-location-outline" title="位置"></i>
            <span
            >{{ detail.provinceid }}-{{ detail.cityid }}-{{
                detail.threeCityid
              }}</span
            >
            <p class="inline_block" v-if="detail.address">
              -{{ detail.address }}
            </p>
          </div>
          <div class="map" title="地图">
            <div id="mapContainer"> </div>
          </div>
        </div>
        <div class="list" v-if="active == 1">
          <div class="city">
            <ul class="flex flex-wrap">
              <li :class="{ active: cityActive == 0 }" @click="citys(0)">
                全部
              </li>
              <li
                  v-for="(n, i) in city"
                  :class="{ active: cityActive == i + 1 }"
                  :key="i"
                  @click="citys(i + 1, n.cityvalue)"
              >
                {{ n.cityid }}
              </li>
            </ul>
          </div>
          <ul>
            <li
                class="box flex"
                v-for="(item, index) in companyList"
                :key="index"
            >
              <div class="box_left">
                <h3>
                  <router-link
                      :to="urlS+'/job/jobDetail/?id=' + item.id"
                      target="_blank"
                  >{{ item.jobName }}
                  </router-link>
                </h3>
                <p>
                  <span
                  >{{ item.cityid }}
                    <span v-if="item.threeCityid">{{
                        "-" + item.threeCityid
                      }}</span
                    ></span>
                  <span v-if="item.edu "><el-divider direction="vertical"></el-divider>{{ item.edu }}</span>
                  <span v-if="item.exp "><el-divider direction="vertical"></el-divider>{{ item.exp }}</span>
                  <span v-if="item.distype.length <= 1"><el-divider direction="vertical"></el-divider><span
                      v-for="(n,i) in item.distype" :key="i">{{ n.disType + '/' + n.disTypeLevel }}</span></span>
                  <span v-else><el-divider direction="vertical"></el-divider>多种残疾</span>
                </p>
              </div>
              <div class="box_right">
                <h1 class="money">
                  {{ item.jobSalary }}
                </h1>
                <div class="time">「{{ item.createTime }}」</div>
              </div>
            </li>
          </ul>
          <div class="pages">
            <el-pagination
                background
                class="work_pagination"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="form.pageNum"
                :page-size="form.pageSize"
                layout="prev, pager, next, total, jumper"
                :total="form.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="info">
          <h2>基本信息</h2>
          <ul>
            <li v-if="detail.hyTop">
              <i class="el-icon-menu"></i>{{ detail.hyTop }}
            </li>
            <li v-if="detail.mun">
              <i class="el-icon-s-custom"></i>{{ detail.mun }}
            </li>
            <li v-if="detail.pr">
              <i class="el-icon-s-data"></i>{{ detail.pr }}
            </li>
            <li v-if="detail.website">
              <i class="el-icon-s-platform"></i>
              <a :href="detail.website" target="_blank">{{ detail.website }}</a>
            </li>
          </ul>
        </div>
        <div class="welfare info">
          <h2>企业福利</h2>
          <div class="span">
            <span v-for="(n, i) in detail.companyLabelOutDTOList" :key="i">{{
                n.labelName
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import selectCity from "@/components/search/selectCity";

export default {
  name: "businessDetails",

  metaInfo() {
    return {
      meta: this.metaData,
    };
  },
  components: {
    selectCity,
  },
  data() {
    return {
      metaData: [],
      active: 0,
      city: [],
      detail: [],
      companyList: [],
      form: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      zoom: 11, //地图展示级别
      postionMap: {
        //经纬度
        lng: "",
        lat: "",
      },
      cityActive: 0,
      loading: false,
      urlS:'',
    };
  },
  methods: {
    async info() {
      this.loading = true;
      try {
        await this.$axios
            .get("/api/app-jycy-company/getCompanyByMemberId", {
              memberId: this.$route.query.companyId,
            })
            .then((res) => {
              this.loading = false;
              if (res.data.success) {
                this.detail = res.data.data;
                this.getDescription();
                this.postionMap.lat = this.detail.latitude;
                this.postionMap.lng = this.detail.longitude;
                this.companyJob();
                this.getAddress(this.detail)
                this.jobCity();
                if (this.detail.website) {
                  this.detail.website = this.detail.website.substr(0, 7).toLowerCase() == "http://" ? this.detail.website : "http://" + this.detail.website;
                }
              }
            })
            .catch((err) => {
              this.loading = false;
            });
      } catch (e) {
        this.loading = false;
      }
    },
    //根据地址获取经纬度
    getAddress(detail) {
      let {
        registerProvinced,
        registerCity,
        registerThreecity,
        registerDetail,
      } = detail
      let address =
          (registerProvinced || '') +
          (registerCity || '') +
          (registerThreecity || '') +
          (registerDetail || '')
      console.log('地图', address)
      var map = new BMapGL.Map('mapContainer')
      // map.centerAndZoom(new BMapGL.Point(116.331398,39.897445), 10); // 初始化地图，设置中心点和地图级别
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
      //创建地址解析器实例
      var myGeo = new BMapGL.Geocoder()
      // 将地址解析结果显示在地图上，并调整地图视野
      myGeo.getPoint(
          address,
          function (point) {
            console.log(point, 'point')
            if (point) {
              map.centerAndZoom(point, 17) // 设置中心点和地图级别
              map.addOverlay(new BMapGL.Marker(point)) // 添加标记点
            } else {
              console.error('您选择的地址没有解析到结果！')
              document.querySelector('#mapContainer').style.display = 'none'
            }
          },
          '全国'
      )
      // 旧版本代码
      // this.$axios
      //     .baiduGet("https://restapi.amap.com/v3/geocode/geo?parameters", {
      //       address: address,
      //       key: "1a461ccf3cde63264b4cbcf2a88f0d1e",
      //     })
      //     .then((res) => {
      //       console.log(res,'res')
      //       this.postionMap.lat = res.data.geocodes[0].location.split(",")[1];
      //       this.postionMap.lng = res.data.geocodes[0].location.split(",")[0];
      //     });
    },
    //公司在招职位
    companyJob(value) {
      this.$axios
          .get("/api/app-jycy-job/getJobListByMemberId", {
            companyId: this.detail.id,
            pageNum: this.form.pageNum,
            pageSize: 10,
            cityid: value,
          })
          .then((res) => {
            if (res.data.success) {
              this.companyList = res.data.data.records;
              this.form.pageNum = res.data.data.current;
              this.form.pageSize = res.data.data.size;
              this.form.total = res.data.data.total;
            }
          })
          .catch((err) => {
          });
    },
    jobCity() {
      this.$axios
          .get("/api/app-jycy-job/getCityListByCompanyId", {
            companyId: this.detail.id,
          })
          .then((res) => {
            if (res.data.success) {
              this.city = res.data.data;
            }
          })
          .catch((err) => {
          });
    },
    //分页
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.companyJob();
    },
    handleSizeChange() {
    },
    citys(i, value) {
      this.cityActive = i;
      this.companyJob(value);
    },
    getDescription() {
      if (this.location == "") {
        this.urlS = '';
        document.title =
            this.detail.companyName + "-兰州市残疾人就业创业网络服务平台";
        this.metaData.push(
            {
              name: "keywords",
              content:
                  this.detail.companyName  +',地址电话,'
                  +this.detail.companyName +
                  "怎么样," +
                  this.detail.companyName +
                  "残疾人工资待遇,兰州市残疾人就业创业网络服务平台",
            },
            {
              name: "description",
              content:
                  "兰州市残疾人就业创业网络服务平台,为残疾人提供" +
                  this.detail.companyName +
                  "的最新残疾人招聘信息,兰州市残疾人就业创业网络服务平台是中国残联官方的残疾人找工作的网站。",
            }
        );
      } else if (this.location == "陕西省") {
        this.urlS = '/shanxi';
        document.title =
            this.detail.companyName + "-兰州市残疾人就业创业网络服务平台";
        this.metaData.push(
            {
              name: "keywords",
              content:
                  this.detail.companyName +',地址电话,'
                  +this.detail.companyName +
                  "怎么样," +
                  this.detail.companyName +
                  "残疾人工资待遇,陕西省残疾人就业创业网络服务平台",
            },
            {
              name: "description",
              content:
                  "兰州市残疾人就业创业网络服务平台,为残疾人提供" +
                  this.detail.companyName +
                  "的最新残疾人招聘信息,陕西省残疾人就业创业网络服务平台是陕西省残联官方的残疾人找工作的网站。",
            }
        );
      } else if (this.location == "甘肃省") {
        document.title =
            this.detail.companyName + "-兰州市残疾人就业创业网络服务平台";
        this.metaData.push(
            {
              name: "keywords",
              content:
                  this.detail.companyName +',地址电话,'
                  +this.detail.companyName +
                  "怎么样," +
                  this.detail.companyName +
                  "残疾人工资待遇,兰州市残疾人就业创业网络服务平台",
            },
            {
              name: "description",
              content:
                  "兰州市残疾人就业创业网络服务平台,为残疾人提供" +
                  this.detail.companyName +
                  "的最新残疾人招聘信息,兰州市残疾人就业创业网络服务平台是兰州市残联官方的残疾人找工作的网站。",
            }
        );
      }
    }
  },
  created() {
    this.info();
    this.$emit("goRouter", "/job/");
    if (this.$route.query.active) {
      this.active = 1;
    }
    //判断是否移动端
    this.$mobile(
        "https://lzmz.cdpee.org.cn/#/pages/index/companyDetails?memberId=" +
        this.$route.query.companyId
    );
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/job.less";
</style>
